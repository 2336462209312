<script setup>
import { useThemeSetting } from "@/Composable/ThemeSetting";
import { computed, onMounted,inject } from "vue";
const props = defineProps({ status: Number });

const __t = inject("__t");
const title = computed(() => {
    return {
        503: __t("503:_Service_Unavailable"),
        500: __t("500:_Server_Error"),
        404: __t("404:_Page_Not_Found"),
        403: __t("403:_Forbidden"),
    }[props.status];
});
const { isDark } = useThemeSetting();
const description = computed(() => {
    return {
        503: "Sorry, we are doing some maintenance. Please check back soon.",
        500: "Whoops, something went wrong on our servers.",
        404: "Sorry, the page you are looking for could not be found.",
        403: "Sorry, you are forbidden from accessing this page.",
    }[props.status];
});

onMounted(() => {

if (typeof document != undefined) {
    // document.body.load = document.getElementById('loading').style.display = 'none';
}
})

const goBack = () =>{
    if (!import.meta.env.SSR) {
        window.history.back();
    }
}
</script>

<template>
    <Head :title="__t(title)"></Head>
    <span>
        <v-no-ssr>
            <div class="absolute z-50 flex items-center justify-center w-10 h-10 rounded-full bg-primary left-10 top-10" @click="goBack()">
                <v-icon>
                    mdi-arrow-left
                </v-icon>
            </div>
        </v-no-ssr>
        <v-row>
            <v-col cols="9" class="flex flex-col items-center justify-center h-screen mx-auto">
                <div class="w-full mx-auto md:w-3/12">
                    <v-img :src="`/assets/images/${status}.svg`" :class="isDark ? 'w-full dark-img' : 'w-full'"></v-img>
                </div>
                <h1 class="text-xl text-center font-bold text-primary mt-5 text-[27px]">
                    {{ __t(`${status}_title`) }}
                </h1>
                <p class="text-center mt-5 text-[14px] leading-[28px] hidden md:block">{{ __t(`${status}_text`) }}</p>
                <div class="flex flex-row items-center justify-center gap-5 mt-5">
                    <!-- <Link :href="route('callUs')">
                        <v-btn variant="outlined" color="on-background">
                            <v-img src="/assets/svgicons/chatp.svg" class="w-[24px] primary-img"></v-img>
                            <p class="px-2 text-black dark:text-white">
                                {{ __t('contact_us') }}
                            </p>
                        </v-btn>
                    </Link> -->
                    <Link :href="route('home')">
                        <v-btn variant="outlined" color="on-background">
                            <v-img src="/assets/svgicons/homep.svg" class="w-[24px] primary-img"></v-img>
                            <p class="px-2 text-black dark:text-white">
                                {{ __t('home') }}
                            </p>
                        </v-btn>
                    </Link>
                </div>
            </v-col>
        </v-row>
    </span>
    <v-img src="/assets/landImages/logo.svg" :class=" isDark ? 'opacity-5 lg:opacity-10 w-80 bottom-1 ltr:right-1 rtl:left-1 fixed dark-img select-none pointer-events-none z-10' : 'w-80 bottom-1 ltr:right-1 rtl:left-1 fixed select-none pointer-events-none opacity-5 lg:opacity-10 z-10'"></v-img>
</template>
<style scoped>
.dark-img {
    filter: invert(100%) sepia(3%) saturate(0%) hue-rotate(321deg) brightness(107%) contrast(104%);
}
</style>
